body {
  margin: 0;
  padding: 0;

  font-family: PingFang SC;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input:focus{
  border: 0 !important;
  outline: none!important;
}
textarea:focus{
  border: 0 !important;
  outline: none!important;
}
header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100vw;
  height: 4vw;
  line-height: 4vw;
  display: flex;
  justify-content: center;
  transition: box-shadow 0.3s ease-out, background 0.3s ease-out;
}
header.show-shadow {
  background: #FDFDFD;
  box-shadow: 0 2px 8px rgba(229, 229, 229, 0.5);
}
header .headerWrapper {
  position: relative;
  width: 100vw;
  height: 7.3vw;
  padding: 0 auto;
  padding-top: 4.2vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
@-webkit-keyframes scaleAnimation {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  /* 初始状态，无放大效果 */
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  /* 放大到原来的1.5倍 */
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  /* 返回原来的大小 */
}
@keyframes scaleAnimation {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  /* 初始状态，无放大效果 */
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  /* 放大到原来的1.5倍 */
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  /* 返回原来的大小 */
}
header .headerWrapper .logo {
  height: 4.6vh;
}
header .headerWrapper .headerBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 8vw;
}
header .headerWrapper .headerBtn .button {
  position: relative;
  cursor: pointer;
  color: #333333;
  width: 6vw;
  margin: 0 0.8vw;
  text-align: center;
  font-size: 1.3vw;
  line-height: 2vw;
  height: 2vw;
  border-width: 0;
  font-family: Impact;
  background-color: transparent;
  box-shadow: 0 0 0;
  font-weight: 600;
}
header .headerWrapper .headerBtn .button:hover {
  border-bottom: 1px solid #1E7C51;
  color: #1E7C51;
}
header .headerWrapper .headerBtn .active-btn {
  color: #1E7C51;
  border-width: 0;
  font-family: Impact;
  border-bottom: 1px solid #1E7C51;
  box-shadow: 0 0 0;
}
header .headerWrapper .headerBtn .active-btn:hover {
  color: #333;
}
header .btn-white {
  position: relative;
  background: #fedb67;
  padding: 0 18vw 0;
}

.text-center {
  text-align: center!important;
}
.text-left {
  text-align: left!important;
}
.layout {
  position: fixed;
  height: 100vh;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}
.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.card {
  text-decoration: none;
}
.card .title,
.card .description {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 14px;
  font-weight: 300;
}
.card:hover .title {
  color: rgba(0, 0, 0, 0.65) !important;
}
.features img {
  margin-top: 0 !important;
}
.block {
  position: relative;
}
.banner {
  position: relative;
  margin: auto;
  width: 100vw;
  background-size: 100vw auto;
  height: 70vh;
  overflow: hidden;
  background-image: url(../../static/media/fsHeader@2x.90effa05.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .bgWrapper {
  position: relative;
  padding: 0 19vw;
  text-align: center;
  display: flex;
}
.banner .bgWrapper .content {
  position: relative;
  margin: 0 auto;
  z-index: 1;
  text-align: center;
  width: 100%;
  padding-top: 19vh;
}
@-webkit-keyframes wave {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  /* 初始状态 */
  50% {
    -webkit-transform: scale(1.2) rotate(-3deg);
            transform: scale(1.2) rotate(-3deg);
  }
  /* 高度放大并向左旋转 */
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  /* 结束状态 */
}
@keyframes wave {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  /* 初始状态 */
  50% {
    -webkit-transform: scale(1.2) rotate(-3deg);
            transform: scale(1.2) rotate(-3deg);
  }
  /* 高度放大并向左旋转 */
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  /* 结束状态 */
}
.banner .bgWrapper .content .title {
  font-family: Arial Black;
  font-weight: bold;
  font-size: 2.5vh;
  font-weight: 400;
  line-height: 3.3vw;
  color: #FFFFFF;
  text-transform: uppercase;
}
.banner .bgWrapper .content .subtitle {
  margin-top: 1.3vh;
  font-size: 2.2vh;
  line-height: 2.2vh;
  color: #fff;
  height: 3.4vw;
  --c: rgba(255, 255, 255, 0.9);
  --w1: radial-gradient(100% 57% at top, #0000 100%, var(--c) 80.5%) no-repeat;
  --w2: radial-gradient(100% 57% at bottom, var(--c) 100%, #0000 80.5%) no-repeat;
  background: var(--w1), var(--w2), var(--w1), var(--w2);
  background-position-x: -100%, -50%, 0%, 100%;
  background-position-y: 0;
  background-size: 30% 40%;
  -webkit-animation: m 5s infinite linear;
          animation: m 5s infinite linear;
  color: transparent;
  -webkit-background-clip: text;
  /*文本裁切*/
  -webkit-text-stroke: 0.1vh var(--c);
}
@-webkit-keyframes m {
  0% {
    background-position-x: -50%, -20%, 25%, 50%;
  }
  50% {
    background-position-x: -100%, -50%, 50%, 100%;
  }
  100% {
    background-position-x: 50%, 100%, 200%, 300%;
  }
}
@keyframes m {
  0% {
    background-position-x: -50%, -20%, 25%, 50%;
  }
  50% {
    background-position-x: -100%, -50%, 50%, 100%;
  }
  100% {
    background-position-x: 50%, 100%, 200%, 300%;
  }
}
.banner .bgWrapper .content .newDesc {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 35vw;
  padding-top: 2vh;
  flex-direction: column;
}
@-webkit-keyframes flip {
  0% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  /* 初始状态，不进行任何旋转 */
  50% {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
  }
  /* 在中间位置将元素水平翻转180度 */
  100% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  /* 结束状态，再次返回到原始位置 */
}
@keyframes flip {
  0% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  /* 初始状态，不进行任何旋转 */
  50% {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
  }
  /* 在中间位置将元素水平翻转180度 */
  100% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  /* 结束状态，再次返回到原始位置 */
}
.banner .bgWrapper .content .desc {
  font-size: 1.3vw;
  font-family: Arial Black;
  font-weight: bold;
  width: 15vw;
  color: #ffffff;
  border: 2px solid #FFFFFF;
  opacity: 1;
  border-radius: 2.6vw;
  height: 4vh;
  line-height: 4vh;
  text-transform: uppercase;
  -webkit-animation-name: flip;
          animation-name: flip;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  /* 2s */
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.banner .bgWrapper .content .desc1 {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  /* 2s */
}
.banner .bgWrapper .content .desc2 {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  /* 2s */
}
.banner .bgWrapper .content .desc3 {
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  /* 2s */
}
.banner .bgWrapper .content .line {
  width: 0.3vh;
  height: 2vh;
  background-color: #fff;
}
.banner .bgWrapper .content .banner-btn {
  margin-top: 2vh;
  margin-bottom: 2.6vw;
  background: rgba(255, 255, 255, 0);
  font-size: 1.2vw;
  font-family: Arial Black;
  font-weight: bold;
  color: #1D2F33;
  opacity: 1;
  border: 0 !important;
  overflow: hidden;
}
.banner .bgWrapper .content .banner-btn a {
  color: #1D2F33;
  text-decoration: initial;
  display: flex;
  justify-content: center;
  align-items: center;
}
@-webkit-keyframes text-slide-up {
  from {
    -webkit-transform: translateX(5vh);
            transform: translateX(5vh);
  }
  to {
    -webkit-transform: translateX(0vh);
            transform: translateX(0vh);
  }
}
@keyframes text-slide-up {
  from {
    -webkit-transform: translateX(5vh);
            transform: translateX(5vh);
  }
  to {
    -webkit-transform: translateX(0vh);
            transform: translateX(0vh);
  }
}
.banner .bgWrapper .content .banner-btn .banner-img {
  position: absolute;
  z-index: 1;
  left: 2vw;
  height: 0.9vw;
  -webkit-animation-name: text-slide-up;
          animation-name: text-slide-up;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  /* 2s */
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes borderAnimation {
  0% {
    border-color: #FB4402;
    /* 定义起始时间点的样式 */
  }
  100% {
    border-color: #fff;
    /* 定义结束时间点的样式 */
  }
}
@keyframes borderAnimation {
  0% {
    border-color: #FB4402;
    /* 定义起始时间点的样式 */
  }
  100% {
    border-color: #fff;
    /* 定义结束时间点的样式 */
  }
}
.banner .bgWrapper .content .banner-btn .banner-txt {
  position: relative;
  z-index: 0;
  padding: 1vh 2vh;
  border: solid 0.5vh transparent;
  /* 设置初始状态为透明 */
  -webkit-animation: borderAnimation 2s infinite alternate;
          animation: borderAnimation 2s infinite alternate;
  /* 应用动画效果并无限重复 */
  background: #ffffff;
  opacity: 1;
  border-radius: 2vw;
  font-family: Impact;
  font-weight: 400;
  color: #FB4402;
  text-transform: uppercase;
}
.banner .bgWrapper .content .banner-btn .banner-txt:hover {
  background-color: #FB4402;
  color: #fff;
}
@-webkit-keyframes slide-up {
  from {
    -webkit-transform: translate(30%, 70%);
            transform: translate(30%, 70%);
  }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
@keyframes slide-up {
  from {
    -webkit-transform: translate(30%, 70%);
            transform: translate(30%, 70%);
  }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
.banner .bgWrapper .imgWrapper {
  position: absolute;
  left: 15vw;
  top: 30vh;
  margin: 0 auto;
  text-align: center;
}
.banner .bgWrapper .imgWrapper img {
  height: 35vh;
}
.banner-ims {
  position: absolute;
  left: -3px;
  top: 0;
  width: 100%;
  height: 150px;
  z-index: 3;
}
.banner-andriod {
  width: 17px;
  height: 20px;
  margin: -5px 10px 0 5px;
}
.banner-newIcon {
  width: 47px;
  height: 47px;
  position: absolute;
  top: -16px;
  right: -8px;
  z-index: 999;
}
.banner-word-box {
  width: 480px;
  height: 400px;
  position: relative;
  display: flex;
  justify-content: flex-start;
}
.banner-word-download {
  width: 275px;
  position: relative;
  height: 110px;
  color: #fff;
  display: flex;
}
.banner-word-download svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  margin-right: 10px;
}
.banner-word-download div {
  position: absolute;
  top: 24px;
  left: 12px;
  z-index: 4;
  color: #fff;
  font-family: Impact;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #ffffff;
  opacity: 1;
}
.banner-word-downloads {
  margin-top: 30px;
  width: 237px;
  position: relative;
  height: 150px;
  color: #fff;
  display: flex;
}
.banner-word-downloads svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  margin-right: 10px;
}
.banner-word-downloads div {
  position: absolute;
  top: 24px;
  left: 20px;
  z-index: 4;
  color: #fff;
  font-family: Impact;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #ffffff;
  opacity: 1;
}
.banner-imgWrapperMobile {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
.banner-imgWrapperMobile img {
  width: 80%;
  height: 80%;
}
.banner-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-text-wrapper {
  width: 40%;
  padding-right: 114px;
  height: 265px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-text-wrapper h1 {
  font-size: 46px;
}
.banner-text-wrapper p {
  font-size: 16px;
  margin: 50px 0 60px;
  font-weight: 300;
}
.andriod {
  width: 17px;
  height: 18px;
}

.footer {
  position: fixed!important;
  z-index: 2!important;
  bottom: 0!important;
  width: 100vw;
  height: 30vh;
  background-image: url(../../static/media/fsFooter@2x.21d1c69c.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 3vh;
}
.footer .bar {
  opacity: 1;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
  padding: 0 !important;
  justify-content: center;
  display: block !important;
}
.footer .bar .one-center {
  font-weight: 600;
  position: relative;
}
.footer .bar .one-center .title {
  padding: 0;
  margin: 0;
  font-size: 2.2vh;
  line-height: 3vh;
  margin-bottom: 1vh;
  font-family: American Typewriter;
  font-weight: bold;
  color: #F9C299;
  text-align: center;
  text-transform: uppercase;
}
.footer .bar .one-center .infos {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8vw;
  color: #fff;
  font-size: 2vh;
  font-family: American Typewriter;
  font-weight: bold;
  line-height: 2.6vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
}
.footer .bar .one-center .infos .contImg {
  width: 2.1vh;
  height: 1.5vh;
  margin-right: 0.7vw;
}
.footer .bar .one-center .infos a {
  font-family: American Typewriter;
  font-weight: bold;
  color: #FFFFFF;
  text-align: right ;
  padding-right: 1vw;
}
.footer .bar .one-center .infos .linkEnd {
  text-align: left;
  padding-left: 1vw;
}
.footer .bar .one-center-end {
  text-align: center;
  margin-bottom: 2vh;
}
.footer .bar .one-center-end .infos {
  text-align: center;
  justify-content: center;
  font-family: American Typewriter;
  font-weight: bold;
  color: #FFFFFF;
}
.footer .bar .one-center-end .infos a {
  font-family: American Typewriter;
  font-weight: bold;
  color: #FFFFFF;
}
.footer .bar .one-center-end .infos:last-child {
  margin-bottom: 0;
}
.footer .bottom-bar {
  position: absolute;
  bottom: 0.2vh;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 3.3vw;
  line-height: 3.3vw;
  text-align: center;
  color: #fff;
  font-size: 1vh;
  font-family: PingFang SC;
  font-weight: 400;
}

.text-center {
  text-align: center!important;
}
.text-left {
  text-align: left!important;
}
.layout {
  position: fixed;
  height: 100vh;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}
#reat-content {
  font-family: Impact;
}
.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.card {
  text-decoration: none;
}
.card .title,
.card .description {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 3.7vw;
  font-weight: 300;
}
.card:hover .title {
  color: rgba(0, 0, 0, 0.65) !important;
}
.bodyMob {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  background: linear-gradient(180deg, #f4a739 0%, #f4a739 100%);
}
.bodyMob .btn-white {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
}
.banner-mobile {
  position: relative;
}
.block-mobile {
  position: relative;
}
.header-mobile {
  position: relative;
}
.headerWrapper-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.headerWrapper-mobile .logo {
  margin-top: 3vh;
  height: 10vw;
  -webkit-animation: rotateAnimation 5s infinite linear;
          animation: rotateAnimation 5s infinite linear;
  /* 设置动画名称、持续时间、重复次数和速度曲线等参数 */
}
@-webkit-keyframes rotateAnimation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  /* 初始状态 */
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  /* 结束状态 */
}
@keyframes rotateAnimation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  /* 初始状态 */
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  /* 结束状态 */
}
.headerWrapper-mobile .popover-menu {
  width: 300px;
}
.headerWrapper-mobile .popover-menu button.lang {
  margin: 4.2vw auto;
  float: none;
}
.headerWrapper-mobile .popover-menu div.version {
  margin: 8.5vw auto 4.2vw;
  float: none;
}
.headerWrapper-mobile .popover-menu .ant-popover-inner {
  padding: 0;
  overflow: hidden;
}
.headerWrapper-mobile .popover-menu .ant-popover-inner-content {
  padding: 0;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul li span {
  color: #2972FB;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul li::selection {
  color: #fff;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul .ant-menu-item-selected {
  color: #fff;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul .ant-menu-item::after {
  border-right: 1vw solid #064852 !important;
}
.headerWrapper-mobile .nav-phone-menu {
  fill: #333333;
  width: 5.3vw;
  height: 5.3vw;
  cursor: pointer;
}
.banner-mobile {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 0;
  background-image: url(../../static/media/fsHeaderM@2x.5da7d429.png);
  background-position: top;
  background-size: 100% 100% ;
  background-repeat: no-repeat;
  height: 80vh;
  overflow-x: hidden;
  -webkit-animation-name: footer-slide-up;
          animation-name: footer-slide-up;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  /* 2s */
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.banner-mobile .bgWrapper {
  background-size: 100% ;
  position: relative;
  margin: 0 auto;
  text-align: left;
  height: 100%;
  padding: 0 1.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
@-webkit-keyframes title-slide-up {
  from {
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
  }
  to {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes title-slide-up {
  from {
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
  }
  to {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
.banner-mobile .bgWrapper .content {
  margin-top: 20vh;
  padding: 0 3.5vw;
  position: relative;
  z-index: 1;
  height: 100%;
  padding-top: 1px;
  color: #FFFFFF;
}
.banner-mobile .bgWrapper .content .title {
  position: relative;
  padding-top: 1.2vw;
  font-size: 4.2vw;
  line-height: 6vw;
  font-weight: bold;
  margin-left: 2vw;
  text-align: center;
}
.banner-mobile .bgWrapper .content .subtitle {
  margin-left: 2vw;
  position: relative;
  margin: 0 10vw;
  text-align: center;
  padding-top: 4.6vw;
  font-size: 3.3vw;
  font-weight: 400;
  line-height: 4.5vw;
  margin-bottom: 0.9vw;
}
.banner-mobile .bgWrapper .content .newDesc {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2vh;
  flex-direction: column;
}
.banner-mobile .bgWrapper .content .desc {
  width: 40vw;
  font-size: 3.3vw;
  font-family: Arial Black;
  font-weight: 400;
  line-height: 4.5vw;
  text-align: center;
  opacity: 1;
  border-radius: 2.6vw;
  padding: 1.5vw 1vw;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
  background: linear-gradient(90deg, #FFFFFF 50%, transparent 0) repeat-x, linear-gradient(90deg, #FFFFFF 50%, transparent 0) repeat-x, linear-gradient(0deg, #FFFFFF 50%, transparent 0) repeat-y, linear-gradient(0deg, #FFFFFF 50%, transparent 0) repeat-y;
  background-size: 3px 3px, 3px 3px, 3px 3px, 3px 3px;
  background-position: 0 0, 0 100%, 0 0, 100% 0;
  -webkit-animation: linearGradientMove 0.2s infinite linear;
          animation: linearGradientMove 0.2s infinite linear;
}
@-webkit-keyframes linearGradientMove {
  100% {
    background-position: 5px 0, -5px 100%, 0 -5px, 100% 5px;
  }
}
@keyframes linearGradientMove {
  100% {
    background-position: 5px 0, -5px 100%, 0 -5px, 100% 5px;
  }
}
.banner-mobile .bgWrapper .content .line {
  width: 0.3vh;
  height: 2vh;
  background-color: #fff;
}
.banner-mobile .bgWrapper .content .word-download {
  margin-top: 10px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.banner-mobile .bgWrapper .content .banner-btn {
  position: relative;
  text-align: center;
  margin-left: 43vw;
  height: 10vw;
  border: 0;
  background: rgba(255, 255, 255, 0);
  opacity: 1;
  border-radius: 2.1vw;
  font-size: 4vw;
  font-family: Arial Black;
  font-weight: bold;
  line-height: 4.6vw;
  margin-top: 10vh;
  display: flex;
  align-items: center;
}
.banner-mobile .bgWrapper .content .banner-btn a {
  text-decoration: initial;
  color: #262626;
  display: flex;
  align-items: center;
}
.banner-mobile .bgWrapper .content .banner-btn .banner-img {
  position: absolute;
  left: 0vh;
  width: 6.2vh;
  height: 6.2vh;
  margin-right: 0.3vw;
}
.banner-mobile .bgWrapper .content .banner-btn .banner-txt {
  display: block;
  background: #f5f5f7;
  opacity: 1;
  border-radius: 21px;
  font-family: Impact;
  font-weight: 400;
  color: #FB4402;
  margin-left: 0;
  padding: 10px 20px;
  -webkit-animation: fadeInOut 2s forwards ease-in-out;
          animation: fadeInOut 2s forwards ease-in-out;
  box-shadow: rgba(18, 18, 18, 0.25) 0px 5px 10px;
  text-transform: uppercase;
}
.banner-mobile .bgWrapper .imgWrapper {
  position: absolute;
  z-index: 0;
  margin-top: 14px;
  bottom: 10vh;
  left: -10vh;
  text-align: center;
}
.banner-mobile .bgWrapper .imgWrapper img {
  height: 150px;
  -webkit-animation: fadeInOut 5s forwards ease-in-out;
          animation: fadeInOut 5s forwards ease-in-out;
}
@-webkit-keyframes fadeInOut {
  0% {
    opacity: 0.1;
  }
  30% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.5;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInOut {
  0% {
    opacity: 0.1;
  }
  30% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.5;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
.banner-mobile-text-wrapper {
  width: 40%;
  padding-right: 13.7vw;
  height: 70.6vw;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-mobile-text-wrapper h1 {
  font-size: 46px;
}
.banner-mobile-text-wrapper p {
  font-size: 4.2vw;
  margin: 13.3vw 0 16vw;
  font-weight: 300;
}
@-webkit-keyframes footer-slide-up {
  from {
    -webkit-transform: translateY(70%);
            transform: translateY(70%);
  }
  to {
    -webkit-transform: translate(0%);
            transform: translate(0%);
  }
}
@keyframes footer-slide-up {
  from {
    -webkit-transform: translateY(70%);
            transform: translateY(70%);
  }
  to {
    -webkit-transform: translate(0%);
            transform: translate(0%);
  }
}
.footer-mobile {
  overflow: hidden;
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 4.5vw;
  height: 30vh;
  padding-top: 0;
  margin-top: 0px;
  background: rgba(245, 245, 247, 0.9);
  margin: 0;
  -webkit-animation-name: footer-slide-up;
          animation-name: footer-slide-up;
  background-image: url(../../static/media/fsFooter@2x.21d1c69c.png);
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  /* 2s */
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.footer-mobile .one-center {
  padding: 3.5vw 3.5vw;
  border-radius: 21px;
  font-family: PingFang SC;
  font-weight: 400;
  position: relative;
  text-align: center;
}
.footer-mobile .one-center .title {
  font-size: 4.5vw;
  font-weight: bold;
  line-height: 5.3vw;
  color: #F9C299;
  opacity: 1;
}
.footer-mobile .one-center .infos {
  height: 4.4vw;
  opacity: 1;
  border-radius: 1.8vw;
  margin: 0 auto;
  font-size: 3.4vw;
  font-weight: 400;
  line-height: 6.4vw;
  color: #ffff;
  margin-top: 2.6vw;
}
.footer-mobile .one-center .infos a {
  color: #ffff;
  text-align: center;
  font-family: PingFang SC;
  font-weight: 600;
  margin-bottom: 3vh;
}
.footer-mobile .one-center-end {
  margin-top: 2.1vw;
  opacity: 1;
  text-align: center;
  padding: 3.5vw 3.5vw;
}
.footer-mobile .one-center-end .title {
  font-size: 4vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 4vw;
  color: #F9C299;
}
.footer-mobile .one-center-end .infos {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.6vw;
}
.footer-mobile .one-center-end .infos div {
  height: 4vw;
  line-height: 4vw;
  font-size: 3.4vw;
  font-family: PingFang SC;
  font-weight: 500;
  color: #fff;
}
.footer-mobile .one-center-end .infos .contImg {
  position: relative;
  width: 3.7vw;
  height: 2.6vw;
  margin-right: 1.8vw;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #064852;
  color: #ffffff;
}
.ant-menu-item::after {
  border-right: 1vw solid #064852 !important;
}

.contentWrapper {
  background: linear-gradient(180deg, #f7853a 0%, #f87b3a 60%, #f8773a 70%, #f4a739 100%);
  padding: 1vw 20vw;
  margin: 0 auto;
}
.contentWrapper h1 {
  font-size: 25px;
  text-align: center;
  font-family: Arial Black;
  font-weight: 400;
  color: #fff;
  font-weight: 500;
}
.contentWrapper h2 {
  font-size: 25px;
  text-align: center;
  color: #fff;
  font-weight: 500;
}
.contentWrapper p {
  text-align: left;
  text-indent: 30px;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}
@media only screen and (max-width: 830px) {
  .contentWrapper {
    padding: 20px;
    width: 100%;
    margin: auto;
    border: 0;
    box-sizing: border-box;
  }
  .contentWrapper h1 {
    font-size: 24px;
    text-align: center;
    font-family: Arial Black;
    font-weight: 400;
    color: #fff;
    font-weight: 500;
  }
  .contentWrapper h2 {
    font-size: 24px;
    text-align: center;
    color: #fff;
    font-weight: 500;
  }
  .contentWrapper p {
    text-align: left;
    text-indent: 30px;
    font-size: 15px;
    line-height: 30px;
    color: #fff;
  }
}

.am-modal-body {
  color: #666;
  font-size: 33px !important;
  display: flex!important;
  height: 100%!important;
  align-items: center!important;
  width: 100%;
  justify-content: center!important;
}
.am-list {
  width: 100%;
}
.am-modal-popup-slide-up {
  top: initial;
  bottom: initial;
  width: 100%;
  display: flex!important;
  height: 100%!important;
  align-items: center!important;
  justify-content: center!important;
}
.am-list-content {
  margin: 0 35px;
}
.ant-notification {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  top: 0!important;
}
.ant-notification-notice {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
}
.ant-notification-notice-content {
  padding: 3vh 2vw;
  line-height: initial;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3vw;
}
.ant-notification-notice-message {
  color: #fff;
  margin: 0!important;
  padding: 1vh 6vw!important;
  font-size: 2.5vh !important;
}
.ant-notification-notice-icon {
  position: relative !important;
}
.ant-notification-notice-icon-error {
  margin: 0;
  padding: 0;
  font-size: 4.5vh;
  line-height: initial;
  color: red;
}
.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: auto!important;
  line-height: initial!important;
  width: auto !important;
}
.ant-notification-topRight {
  width: 300px;
}
.ant-notification-notice-close-x {
  position: fixed!important;
  left: 0!important;
  right: 0!important;
  z-index: 100!important;
  bottom: 35vh!important;
  width: 4vh;
  height: 4vh;
  line-height: 4vh;
  text-align: center!important;
  margin: 0 auto!important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 50%;
}
.ant-notification-notice-close-x:after {
  font-size: 3vh!important;
  line-height: 4vh;
}
.errorModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}
.errorContent {
  position: relative;
  border-radius: 1.5vh;
  margin: 0 15vw;
  background-color: #ffffff;
  overflow: hidden;
}
.loadingContent {
  background-color: rgba(0, 0, 0, 0.5);
}
.errorHeader {
  background: linear-gradient(180deg, #F9C299 0%, #FFFFFF 100%);
  color: #FB4402;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2vw 0;
}
.errorMsg {
  color: #FB4402;
  text-align: center;
}
.loadingMsg {
  color: #fff;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.btn {
  background-color: #FB4402;
  color: #fff;
  cursor: pointer;
}
.deleteWrapper-mob {
  position: relative;
  min-height: 100vh;
  background-image: url(../../static/media/delBg.970a7b16.png);
  background-size: 120% auto ;
  background-position: center;
  background-repeat: no-repeat;
}
.deleteWrapper-mob .tabNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 90vw;
  z-index: 100;
  z-index: 0;
}
.deleteWrapper-mob .tabNav .title {
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0;
  padding-top: 8.7vw;
  font-size: 3.9vw;
  text-transform: uppercase;
}
.deleteWrapper-mob .content {
  position: fixed;
  top: 22vw;
  left: 4.5vw;
  z-index: 1;
  right: 4.5vw;
  border-radius: 0 0 2.8vw 2.8vw;
}
.deleteWrapper-mob .desc {
  position: relative;
  display: flex;
  padding-right: 0;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 25px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 38px;
  color: #FB4402;
  text-align: left;
  padding: 0 35px;
}
.deleteWrapper-mob .fbtInfo {
  position: relative;
  opacity: 1;
  border-radius: 21px;
  border: 1px solid #FFFFFF;
  border-radius: 3.2vw;
  overflow: hidden;
  padding: 9.3vw 4.6vw;
  padding-top: 14.8vw;
}
.deleteWrapper-mob .fbtInfo .tip {
  position: absolute;
  text-transform: uppercase;
  top: -2px;
  left: 0;
  text-align: center;
  font-size: 3.9vw;
  padding: 2.4vw 4.2vw;
  font-family: Roboto;
  font-weight: bold;
  color: #FB4402;
  background: #f5f5f7;
  letter-spacing: 0px;
  border-radius: 2.8vw 0px 2.8vw 0px;
}
.deleteWrapper-mob .fbtCol {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 42px;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #FB4402;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
}
.deleteWrapper-mob .fbtCol .need {
  color: #FB4402;
}
.deleteWrapper-mob .fbtPhone {
  background: rgba(245, 245, 247, 0);
  border-bottom: 2px solid #F9C299;
  padding: 2.2vw 0;
  opacity: 1;
  width: 100%;
  margin-bottom: 4.6vw;
}
.deleteWrapper-mob .fbtPhone .fpInfo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpLabel {
  flex: 4 1;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  margin-left: 4.6vw;
  font-size: 3.9vw;
  height: 5vw;
  line-height: 5vw;
  text-align: right;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput {
  position: relative;
  flex: 5 1;
  font-family: Roboto;
  font-weight: bold;
  caret-color: #FB4402;
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
  letter-spacing: 0px;
  height: 5vw;
  text-align: left;
  line-height: 5vw;
  font-size: 3.9vw;
  margin-left: 2.8vw;
  border: 0;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput::-webkit-input-placeholder {
  color: #F9C299;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.9vw;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput::placeholder {
  color: #F9C299;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.9vw;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpIdCard {
  margin-left: 0;
}
.deleteWrapper-mob .fbtPhone .showTips {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2.2vw;
  padding-top: 1.2vw;
}
.deleteWrapper-mob .fbtPhone .showTips span {
  font-size: 2.9vw;
  color: #ffffff;
}
.deleteWrapper-mob .fbtPhone::-webkit-input-placeholder {
  color: #F9C299;
  font-weight: 400;
}
.deleteWrapper-mob .fbtPhone::placeholder {
  color: #F9C299;
  font-weight: 400;
}
.deleteWrapper-mob .fbtTop .fpInfo .fpInput {
  text-align: left;
}
.deleteWrapper-mob .fbtTop .fpInfo .fpInput::-webkit-input-placeholder {
  text-align: left;
  font-weight: 400;
}
.deleteWrapper-mob .fbtTop .fpInfo .fpInput::placeholder {
  text-align: left;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect {
  padding: 0 4.6vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgba(245, 245, 247, 0);
  border-bottom: 2px solid #F9C299;
  opacity: 1;
  padding-top: 2.2vw;
  padding-bottom: 2.2vw;
  font-size: 3.9vw;
  margin-bottom: 4.6vw;
}
.deleteWrapper-mob .itemSelect .iSelect {
  border: 0;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  height: 5vw;
  line-height: 5vw;
  color: #fff;
  width: 100%;
  text-align: center;
  background: initial;
  cursor: pointer;
}
.deleteWrapper-mob .itemSelect .itemUppercase {
  text-transform: uppercase;
}
.deleteWrapper-mob .itemSelect .iSelect::-webkit-input-placeholder {
  color: #F9C299;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect .iSelect::placeholder {
  color: #F9C299;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect .itemImg {
  width: 3.2vw;
  height: 2.8vw;
  cursor: pointer;
}
.deleteWrapper-mob .fbtContent {
  width: 100%;
  padding: 0 2.2vw;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #000;
  letter-spacing: 0px;
  opacity: 1;
  background: rgba(245, 245, 247, 0);
  border-bottom: 2px solid #F9C299;
  box-sizing: border-box;
}
.deleteWrapper-mob .fbtContent .fpLabel {
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  text-align: left;
}
.deleteWrapper-mob .fbtContent .fpText {
  border: 0 !important;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  background-color: rgba(245, 245, 247, 0);
  text-align: center;
  caret-color: #F9C299;
  width: 100%;
  max-height: 8vw;
  font-size: 3.9vw;
  line-height: 4.2vw;
}
.deleteWrapper-mob .fbtContent .fpText::-webkit-input-placeholder {
  color: #F9C299;
  font-size: 3.9vw;
  height: 5vw;
  line-height: 5vw;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper-mob .fbtContent .fpText::placeholder {
  color: #F9C299;
  font-size: 3.9vw;
  height: 5vw;
  line-height: 5vw;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect::-webkit-input-placeholder {
  color: #F9C299;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect::placeholder {
  color: #F9C299;
  font-weight: 400;
}
.deleteWrapper-mob .fbtContentOn {
  border-bottom: 2px solid #ffffff;
  opacity: 1;
}
.deleteWrapper-mob .idCard {
  opacity: 0.7;
}
.deleteWrapper-mob .idCard .fpInfo {
  opacity: 0.7;
}
.deleteWrapper-mob .idCard .fpInfo .fpInput {
  opacity: 0.7;
}
.deleteWrapper-mob .agreeWrapper {
  margin-top: 5.9vw;
  margin-bottom: 10vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.deleteWrapper-mob .agreeWrapper .laCheck {
  position: relative;
  border: 1px solid #ffffff;
  width: 3.6vw;
  height: 3.6vw;
  border-radius: 50%;
}
.deleteWrapper-mob .agreeWrapper .hasCheck {
  background-color: #ffffff;
}
.deleteWrapper-mob .agreeWrapper .hasCheck .laImg {
  position: absolute;
  top: 0.3vw;
  width: 3.6vw;
  height: 2.7vw;
}
.deleteWrapper-mob .agreeWrapper .laText {
  display: flex;
  margin-left: 2.6vw;
  color: #000;
  letter-spacing: 0px;
  font-size: 3vw;
  font-family: Roboto;
  font-weight: 400;
  line-height: 3vw;
  cursor: pointer;
}
.deleteWrapper-mob .agreeWrapper .laText .normolColor {
  color: #ffffff;
}
.deleteWrapper-mob .agreeWrapper .laText .doc {
  text-decoration: underline;
  color: #ffffff;
  font-weight: bold;
  padding: 0 0.2vw;
}
.deleteWrapper-mob .fbtAction {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 11vw;
  height: 13.3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  opacity: 0.7;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #FB4402;
  letter-spacing: 0px;
  margin: 0 22vw;
  border-radius: 6.9vw;
}
.deleteWrapper-mob .fbtActionOn {
  background: #ffffff;
  opacity: 1;
}
.deleteWrapper-mob .fbtSuccess {
  position: relative;
  display: flex;
  height: 100vh;
  margin: 0 auto;
  padding: 0 3.5vw;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
}
.deleteWrapper-mob .fbtSuccess span {
  font-size: 4.8vw;
  text-align: center;
  font-family: Roboto;
}
.deleteWrapperColor-mob {
  padding-top: 0;
  background-color: #f7f7f7;
  color: #fb4402;
}
.deleteWrapperColor-mob .tabNav {
  height: 100vh;
}
.copySuccessTip-mob {
  height: 54px;
  align-items: center;
  display: flex;
  padding: 5px 0;
}
.copySuccessTip-mob .iconsmile {
  width: 54px;
  height: 54px;
  fill: #fb4402;
  margin-right: 9px;
}
.copySuccessTip-mob span {
  font-size: 33px;
  font-family: Roboto;
  font-weight: bold;
  color: #fb4402;
  opacity: 1;
}
.edit-mob .content {
  overflow-y: scroll;
  height: 70vh !important;
}
.edit-mob .content .fbtInfo {
  display: block;
  height: 100%;
  padding-bottom: 80vw;
}
.selectModal-mob .modalContent {
  border-radius: 0.9vw;
  margin: 0 4.6vw;
}
.selectModal-mob .modalContent .listHead {
  font-size: 3.9vw;
  height: 15vw;
}
.selectModal-mob .modalContent .list .listItem {
  font-size: 3.9vw;
  margin: 0 4.6vw;
  border-bottom: 1px dashed #333;
}
.selectModal-mob .modalContent .list .listItem:last-child {
  border-bottom: 0;
}
.errorMsg-mob {
  font-size: 4.6vw !important;
  padding: 3.6vw !important;
}
.loader-mob {
  border: 0.5vh solid #fff;
  border-top: 0.5vh solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 5vh;
  height: 5vh;
  margin: 0 auto;
  margin-top: 5vh;
  -webkit-animation: spin 0.5s linear infinite;
          animation: spin 0.5s linear infinite;
}
.btn-mob {
  margin: 0 17vw;
  border-radius: 6.9vw;
  font-size: 4.6vw;
  padding: 1vw 4vw;
  margin-bottom: 3.5vw;
}

.am-modal-body {
  color: #666;
  font-size: 33px !important;
  display: flex!important;
  height: 100%!important;
  align-items: center!important;
  width: 100%;
  justify-content: center!important;
}
.am-list {
  width: 100%;
}
.am-modal-popup-slide-up {
  top: initial;
  bottom: initial;
  width: 100%;
  display: flex!important;
  height: 100%!important;
  align-items: center!important;
  justify-content: center!important;
}
.am-list-content {
  margin: 0 35px;
}
.ant-notification {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  top: 0!important;
}
.ant-notification-notice {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
}
.ant-notification-notice-content {
  padding: 3vh 2vw;
  line-height: initial;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3vw;
}
.ant-notification-notice-message {
  color: #fff;
  margin: 0!important;
  padding: 1vh 6vw!important;
  font-size: 2.5vh !important;
}
.ant-notification-notice-icon {
  position: relative !important;
}
.ant-notification-notice-icon-error {
  margin: 0;
  padding: 0;
  font-size: 4.5vh;
  line-height: initial;
  color: red;
}
.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: auto!important;
  line-height: initial!important;
  width: auto !important;
}
.ant-notification-topRight {
  width: 300px;
}
.ant-notification-notice-close-x {
  position: fixed!important;
  left: 0!important;
  right: 0!important;
  z-index: 100!important;
  bottom: 35vh!important;
  width: 4vh;
  height: 4vh;
  line-height: 4vh;
  text-align: center!important;
  margin: 0 auto!important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 50%;
}
.ant-notification-notice-close-x:after {
  font-size: 3vh!important;
  line-height: 4vh;
}
.errorModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}
.errorContent {
  position: relative;
  border-radius: 1.5vh;
  margin: 0 15vw;
  background-color: #ffffff;
  overflow: hidden;
}
.loadingContent {
  background-color: rgba(0, 0, 0, 0.5);
}
.errorHeader {
  background: linear-gradient(180deg, #F9C299 0%, #FFFFFF 100%);
  color: #FB4402;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2vw 0;
}
.errorMsg {
  color: #FB4402;
  text-align: center;
}
.loadingMsg {
  color: #fff;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.btn {
  background-color: #FB4402;
  color: #fff;
  cursor: pointer;
}
.deleteWrapper {
  position: relative;
  padding: -5vh;
  min-height: 100vh;
  background-image: url(../../static/media/delBg.970a7b16.png);
  background-size: 120% auto ;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}
.deleteWrapper .tabNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70vh;
  z-index: 1;
}
.deleteWrapper .tabNav .title {
  padding-top: 3.5vh;
  font-size: 2.6vh;
  font-family: Roboto;
  font-weight: bold;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0px;
}
.deleteWrapper .content {
  position: fixed;
  z-index: 1;
  top: 10vh;
  height: 90vh;
  left: 5vw;
  right: 5vw;
}
.deleteWrapper .desc {
  position: relative;
  display: flex;
  padding-right: 0;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 2.3vh;
  font-family: Roboto;
  font-weight: 400;
  line-height: 2.5vh;
  color: #FB4402;
  text-align: left;
}
.deleteWrapper .fbtInfo {
  position: relative;
  opacity: 1;
  padding: 6.9vh 3.5vh;
  padding-top: 10vh;
  border-radius: 2.4vh;
  border: 1px solid #FFFFFF;
  overflow: hidden;
}
.deleteWrapper .fbtInfo .tip {
  position: absolute;
  background: #ffffff;
  top: -2px;
  left: 0;
  padding: 1vw 4vh;
  text-transform: uppercase;
  text-align: center;
  font-size: 3vh;
  font-family: Roboto;
  font-weight: bold;
  color: #FB4402;
  letter-spacing: 0px;
  background: #f5f5f7;
  opacity: 1;
  border-radius: 2.4vh 0px 2.4vh 0px;
}
.deleteWrapper .fbtCol {
  position: relative;
  padding-top: 1.4vh;
  padding-bottom: 1.2vh;
  font-size: 2.2vh;
  line-height: 2.5vh;
  font-family: Roboto;
  font-weight: 400;
  color: #FB4402;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
  padding-left: 4.5vh;
}
.deleteWrapper .fbtCol .need {
  color: #FB4402;
}
.deleteWrapper .fbtPhone {
  background: rgba(245, 245, 247, 0);
  padding: 1vh 0;
  opacity: 1;
  width: 100%;
  margin-bottom: 5vh;
  border-bottom: 2px solid #F9C299;
}
.deleteWrapper .fbtPhone .fpInfo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.deleteWrapper .fbtPhone .fpInfo .fpLabel {
  flex: 4 1;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  margin-left: 2.2vw;
  font-size: 2vh;
  height: 2.2vh;
  line-height: 2.2vh;
  text-align: right;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput {
  position: relative;
  flex: 5 1;
  font-family: Roboto;
  font-weight: bold;
  caret-color: #FB4402;
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
  letter-spacing: 0px;
  height: 3.2vh;
  line-height: 3.2vh;
  font-size: 2vh;
  margin-left: 1.1vw;
  border: 0;
  text-align: center;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput::-webkit-input-placeholder {
  color: #F9C299;
  font-size: 2vh;
  height: 3.2vh;
  font-weight: 400;
  line-height: 3.2vh;
  text-align: center;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput::placeholder {
  color: #F9C299;
  font-size: 2vh;
  height: 3.2vh;
  font-weight: 400;
  line-height: 3.2vh;
  text-align: center;
}
.deleteWrapper .fbtPhone .fpInfo .fpIdCard {
  margin-left: 0;
}
.deleteWrapper .fbtPhone .showTips {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2.2vw;
}
.deleteWrapper .fbtPhone .showTips span {
  font-size: 2vh;
  color: #ffffff;
}
.deleteWrapper .fbtPhone .showTips img {
  margin-left: 2.2vw;
  width: 2vh;
  height: 2vh;
}
.deleteWrapper .fbtTop .fpInfo .fpInput {
  text-align: left;
}
.deleteWrapper .fbtTop .fpInfo .fpInput::-webkit-input-placeholder {
  text-align: left;
  font-weight: 400;
}
.deleteWrapper .fbtTop .fpInfo .fpInput::placeholder {
  text-align: left;
  font-weight: 400;
}
.deleteWrapper .fbtPhone::-webkit-input-placeholder {
  color: #F9C299;
  font-weight: 400;
}
.deleteWrapper .fbtPhone::placeholder {
  color: #F9C299;
  font-weight: 400;
}
.deleteWrapper .itemSelect {
  padding: 0 2.2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgba(245, 245, 247, 0);
  border-bottom: 2px solid #F9C299;
  opacity: 1;
  padding-top: 1vh;
  padding-bottom: 1vh;
  font-size: 2vh;
  margin-bottom: 5vh;
}
.deleteWrapper .itemSelect .iSelect {
  border: 0;
  font-size: 2vh;
  font-family: Roboto;
  font-weight: bold;
  height: 3.2vh;
  line-height: 3.2vh;
  color: #fff;
  width: 100%;
  background: initial;
  text-align: center;
  cursor: pointer;
}
.deleteWrapper .itemSelect .itemUppercase {
  text-transform: uppercase;
}
.deleteWrapper .itemSelect .iSelect::-webkit-input-placeholder {
  color: #F9C299;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper .itemSelect .iSelect::placeholder {
  color: #F9C299;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper .itemSelect .itemImg {
  position: absolute;
  right: 2.5vw;
  width: 3.2vh;
  height: 2.8vh;
  cursor: pointer;
}
.deleteWrapper .fbtContent {
  width: 100%;
  padding: 0 2.2vw;
  font-size: 2vh;
  font-family: Roboto;
  font-weight: bold;
  color: #000;
  letter-spacing: 0px;
  opacity: 1;
  border-bottom: 2px solid #F9C299;
  background: rgba(245, 245, 247, 0);
  box-sizing: border-box;
}
.deleteWrapper .fbtContent .fpLabel {
  font-size: 21px;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  text-align: left;
}
.deleteWrapper .fbtContent .fpText {
  border: 0 !important;
  font-weight: bold;
  color: #fff;
  background-color: rgba(245, 245, 247, 0);
  font-family: Roboto;
  caret-color: #F9C299;
  text-align: center;
  width: 100%;
  max-height: 4.6vh;
  font-size: 2vh;
}
.deleteWrapper .fbtContent .fpText::-webkit-input-placeholder {
  color: #F9C299;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper .fbtContent .fpText::placeholder {
  color: #F9C299;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
  text-align: center;
  font-weight: 400;
}
.deleteWrapper .itemSelect::-webkit-input-placeholder {
  color: #F9C299;
  font-weight: 400;
}
.deleteWrapper .itemSelect::placeholder {
  color: #F9C299;
  font-weight: 400;
}
.deleteWrapper .fbtContentOn {
  border-bottom: 2px solid #ffffff;
  opacity: 1;
}
.deleteWrapper .idCard {
  opacity: 0.7;
}
.deleteWrapper .idCard .fpInfo {
  opacity: 0.7;
}
.deleteWrapper .idCard .fpInfo .fpInput {
  opacity: 0.7;
}
.deleteWrapper .agreeWrapper {
  margin: 0 auto;
  margin-top: 2.2vh;
  margin-bottom: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.deleteWrapper .agreeWrapper .laCheck {
  position: relative;
  border: 1px solid #ffffff;
  width: 2.6vh;
  height: 2.6vh;
  border-radius: 50%;
  margin-left: 8px;
  margin-top: 2px;
  margin-right: 2.1vh;
}
.deleteWrapper .agreeWrapper .hasCheck {
  margin-left: 8px;
  margin-top: 2px;
  background-color: #ffffff;
}
.deleteWrapper .agreeWrapper .hasCheck .laImg {
  position: absolute;
  left: 0.1vh;
  top: 0.5vh;
  height: 1.6vh;
}
.deleteWrapper .agreeWrapper .laText {
  display: flex;
  margin-left: 1.1vh;
  color: #000;
  letter-spacing: 0px;
  font-size: 2.1vh;
  font-family: Roboto;
  font-weight: 400;
  line-height: 3.1vh;
  cursor: pointer;
}
.deleteWrapper .agreeWrapper .laText .normolColor {
  color: #ffffff;
}
.deleteWrapper .agreeWrapper .laText .doc {
  text-decoration: underline;
  color: #ffffff;
  font-weight: bold;
  padding: 0 0.2vw;
}
.deleteWrapper .fbtAction {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-weight: bold;
  background: #ffffff;
  bottom: 2vh;
  height: 8.8vh;
  opacity: 0.7;
  font-size: 3.3vh;
  color: #FB4402;
  letter-spacing: 0px;
  margin: 0 20vw;
  border-radius: 4.2vh;
  cursor: pointer;
}
.deleteWrapper .fbtActionOn {
  opacity: 1;
}
.deleteWrapper .fbtSuccess {
  position: relative;
  display: flex;
  height: 90vh;
  z-index: 100;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 4.8vh;
  font-family: Roboto;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
}
.deleteWrapper .fbtSuccess span {
  font-size: 4.8vw;
  text-align: center;
  font-family: Roboto;
}
.deleteWrapperColor {
  background-color: #FB4402;
  color: #fb4402;
  padding-top: 0;
}
.deleteWrapperColor .tabNav {
  height: 100vh;
}
.copySuccessTip {
  height: 54px;
  align-items: center;
  display: flex;
  padding: 5px 0;
}
.copySuccessTip .iconsmile {
  width: 54px;
  height: 54px;
  fill: #fb4402;
  margin-right: 9px;
}
.copySuccessTip span {
  font-size: 33px;
  font-family: Roboto;
  font-weight: bold;
  color: #fb4402;
  opacity: 1;
}
.edit {
  height: auto;
  padding-bottom: 40vh;
}
.list {
  max-height: 60vh;
  overflow: scroll;
  text-align: center;
}
.list .am-list-content {
  overflow: initial !important;
  text-overflow: initial !important;
  white-space: initial!important;
  font-size: 2.1vh !important;
  padding-top: 1.2vh !important;
  padding-bottom: 1.2vh !important;
}
.list .am-radio-input {
  height: 5vw;
}
.list .am-list-item {
  min-height: 4vh!important;
}
.list .am-radio {
  height: 4vh!important;
}
.list .am-radio-inner {
  right: 0.5vh !important;
  top: 2vw!important;
  width: 2.2vh;
  height: 2.2vh;
}
.list .am-radio-inner:after {
  width: 1.2vh;
  height: 2.2vh;
}
.list .listItem {
  text-align: center;
}
.list .listItemTip {
  font-size: 2.1vh;
  color: #999999;
  text-align: center;
}
.list .listItemTip .listItemTSign {
  color: #B92D00;
}
.selectModal {
  position: fixed;
  left: 0 ;
  right: 0;
  z-index: 100;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
}
.modalContent {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 0.9vw;
  background: #fff;
  margin: 0 3.5vh;
}
.modalContent .listHead {
  background: linear-gradient(180deg, #F9C299 0%, #FFFFFF 100%);
  font-weight: bold;
  color: #FB4402;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 1;
  height: 10vh;
  line-height: 10vh;
  font-size: 3.2vh;
  font-family: Roboto;
}
.modalContent .listHead .icon {
  width: 35px;
  height: 35px;
}
.modalContent .list {
  position: relative;
  background-color: #fff;
  overflow: auto;
  text-align: center;
  max-height: 60vh;
}
.modalContent .list .listItem {
  position: relative;
  margin: 0 69px;
  text-align: center;
  color: #1D2F33;
  font-weight: bold;
  cursor: pointer;
  padding: 1.5vh 0;
  border-bottom: 1px dashed #F9C299;
}
.modalContent .list .listItemOn {
  color: #FB4402;
  background-color: rgba(113, 162, 255, 0);
  font-weight: bold;
}
.modalContent .list .listItemOn .listItem {
  position: relative;
  color: #FB4402;
}
.modalContent .list .listItem:last-child {
  border-bottom: 0;
}
.modalContent .list::-webkit-scrollbar {
  display: block;
  width: 10px;
  height: 40px;
}
.modalContent .list::-webkit-scrollbar-button {
  color: #333333;
}
.modalContent .list::-webkit-scrollbar-thumb {
  background-position: center;
  border-radius: 10px;
  height: 40px;
  background-color: blue;
}
.loader {
  border: 0.5vh solid #fff;
  border-top: 0.5vh solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 5vh;
  height: 5vh;
  margin: 0 auto;
  margin-top: 5vh;
  -webkit-animation: spin 0.5s linear infinite;
          animation: spin 0.5s linear infinite;
}
.errorMsg {
  font-size: 2.2vh;
  padding: 3.3vh;
}
.btn {
  margin: 0 8vh;
  border-radius: 6.9vh;
  font-size: 2.2vh;
  padding: 1vh 4vh;
  margin-bottom: 3.5vh;
}

